import React, {useEffect, useState, useMemo, useRef} from 'react';
import {useMapState} from '../../store';
import MapModal from "./MapModal";
import MapGL, {Source, Layer, Marker, FullscreenControl} from '@urbica/react-map-gl';
// @ts-ignore
import Cluster from '@urbica/react-map-gl-cluster';
import 'mapbox-gl/dist/mapbox-gl.css';
import {CityUpdate, City} from "../../../types";
// @ts-ignore
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import useSWR from 'swr'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;


const MapScreen = () => {
    const [cities, setCities] = useState<CityUpdate[]>([])
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [activeCity, setActiveCity] = useState<CityUpdate | null>(null)
    const stateLocation = useMapState((state) => state.location)
    const mapBoxToken = 'pk.eyJ1IjoiYmFyYnVkb3VyIiwiYSI6ImNrbjM4cWJ1dTA1M2Myd3M5cnE0YXZwbmoifQ.Sl3KhefjFE36AImP8zVmbg'

    const minZoom = 12
    const maxZoom = 5
    const defaultLat = 55.755811
    const defaultLon = 37.617617

    const [viewport, setViewport] = useState({
        latitude: defaultLat,
        longitude: defaultLon,
        zoom: maxZoom
    });

    // @ts-ignore
    const fetcher = (...args) => fetch(...args).then(res => res.json())
    const { data, error, isLoading } = useSWR(process.env.REACT_APP_API_URL + '/cities', fetcher)

    useEffect(() => {
        if (data) {
            const updData = data.map((city: City) => ({
                ...city,
                location: city.location.split(',').map(Number)
            }))
            setCities(updData)
            setActiveCity(updData[0])
        }
        // fetch(process.env.REACT_APP_API_URL + '/cities')
        //     .then((response) => response.json())
        //     .then((data) => {
        //         const updData = data.map((city: City) => ({
        //             ...city,
        //             location: city.location.split(',').map(Number)
        //         }))
        //         setCities(updData)
        //         setActiveCity(updData[0])
        //     })
    }, [data])

    const moveMapToStartPoint = () => {
        setViewport({
            latitude: defaultLat,
            longitude: defaultLon,
            zoom: maxZoom
        })
    }

    const mapRef = useRef<any>();

    // @ts-ignore
    const ClusterMarker = ({longitude, latitude, pointCount}) => (
        <Marker longitude={longitude} latitude={latitude}>
            <div className={'bg-white w-24 h-24 rounded-full z-40 drop-shadow-2xl text-[#2F5DFF] flex justify-center items-center whitespace-nowrap text-4xl uppercase font-bold'}>{pointCount}</div>
        </Marker>
    );

    return (
        <div className={'fixed w-screen h-screen inset-0'}>
            <MapGL
                ref={mapRef}
                style={{width: '100%', height: '100%'}}
                mapStyle='mapbox://styles/barbudour/clcufjhql00ga14lclooyod0k/draft'
                accessToken={mapBoxToken}
                latitude={viewport.latitude}
                longitude={viewport.longitude}
                zoom={viewport.zoom}
                onViewportChange={setViewport}
                minZoom={maxZoom}
                maxZoom={minZoom}
                antialias={false}
                maxBounds={[-180.0, 41.151416124, 180.0, 81.2504]}
                maxTileCacheSize={2048}
            >
                <MapModal isOpen={modalOpen} toggleModal={setModalOpen} closeModal={moveMapToStartPoint} city={activeCity}/>
                <FullscreenControl/>
                <Source id='contours' type='vector' url='mapbox://mapbox.mapbox-terrain-v2'/>
                <Layer
                    id='contours'
                    type='line'
                    source='contours'
                    source-layer='contour'
                    paint={{
                        'line-color': '#877b59',
                        'line-width': 1
                    }}
                />
                <Cluster radius={40} extent={512} nodeSize={64} component={ClusterMarker}>
                    {cities.map((city) => (
                        <Marker key={city.id} longitude={city.location[1]} latitude={city.location[0]}>
                            <div className={'relative'} onClick={() => {
                                setActiveCity(city)
                                setModalOpen(true)
                            }}>
                                <h3 className={'absolute left-1/2 -translate-x-1/2 bottom-10 whitespace-nowrap text-2xl uppercase font-bold bg-white text-[#2F5DFF] rounded-full py-2 px-4 z-40 drop-shadow-2xl'}>{city.name}</h3>
                                <span className="relative flex justify-center items-center h-5 w-5 z-10">
                              <span
                                  className="animate-ping duration-1000 absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
                              <span className="relative inline-flex rounded-full h-3 w-3 bg-white"></span>
                            </span>
                            </div>
                        </Marker>
                    ))}
                </Cluster>
            </MapGL>
        </div>
    );
};

export default MapScreen;
