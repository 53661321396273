import create from 'zustand'
import {subscribeWithSelector} from 'zustand/middleware'

interface IMapState {
    location: number,
    setLocation: (location: number) => void
}

export const useMapState = create<IMapState>()(subscribeWithSelector(
    (set) => ({
        location: 1,
        setLocation: (location) => set((state) => ({...state, location}))
    })
))
