import React from 'react';
import {useMapState} from "../../store";

const MapController = () => {
    const currentLocation = useMapState((state) => state.location)
    const setLocation = useMapState((state) => state.setLocation)

    return (
        <div>
            map controller<br />
            <button onClick={() => setLocation(currentLocation + 1)}>change location ({currentLocation})</button>
        </div>
    );
};

export default MapController;
