import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import MapController from "./components/common/MapController";
import MapScreen from "./components/common/MapScreen";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MapController/>,
    },
    {
        path: "/screen",
        element: <MapScreen/>
    }
]);

function App() {
    return (
        <div className="App">
            <React.StrictMode>
                <RouterProvider router={router}/>
            </React.StrictMode>
        </div>
    );
}

export default App;
